export default function countryCode(code, wantedKey = "alpha_3") {
  const arr = [
    { country: "Afghanistan", alpha_2: " AF", alpha_3: "AFG" },
    { country: "Aland Islands", alpha_2: "AX", alpha_3: "ALA" },
    { country: "Albania", alpha_2: "AL", alpha_3: "ALB" },
    { country: "Algeria", alpha_2: "DZ", alpha_3: "DZA" },
    { country: "American Samoa", alpha_2: "AS", alpha_3: "ASM" },
    { country: "Andorra", alpha_2: "AD", alpha_3: "AND" },
    { country: "Angola", alpha_2: "AO", alpha_3: "AGO" },
    { country: "Anguilla", alpha_2: "AI", alpha_3: "AIA" },
    { country: "Antarctica", alpha_2: "AQ", alpha_3: "ATA" },
    { country: "Antigua and Barbuda", alpha_2: "AG", alpha_3: "ATG" },
    { country: "Argentina", alpha_2: "AR", alpha_3: "ARG" },
    { country: "Armenia", alpha_2: "AM", alpha_3: "ARM" },
    { country: "Aruba", alpha_2: "AW", alpha_3: "ABW" },
    { country: "Australia", alpha_2: "AU", alpha_3: "AUS" },
    { country: "Austria", alpha_2: "AT", alpha_3: "AUT" },
    { country: "Azerbaijan", alpha_2: "AZ", alpha_3: "AZE" },
    { country: "Bahamas", alpha_2: "BS", alpha_3: "BHS" },
    { country: "Bahrain", alpha_2: "BH", alpha_3: "BHR" },
    { country: "Bangladesh", alpha_2: "BD", alpha_3: "BGD" },
    { country: "Barbados", alpha_2: "BB", alpha_3: "BRB" },
    { country: "Belarus", alpha_2: "BY", alpha_3: "BLR" },
    { country: "Belgium", alpha_2: "BE", alpha_3: "BEL" },
    { country: "Belize", alpha_2: "BZ", alpha_3: "BLZ" },
    { country: "Benin", alpha_2: "BJ", alpha_3: "BEN" },
    { country: "Bermuda", alpha_2: "BM", alpha_3: "BMU" },
    { country: "Bhutan", alpha_2: "BT", alpha_3: "BTN" },
    { country: "Bolivia", alpha_2: "BO", alpha_3: "BOL" },
    { country: "Bosnia and Herzegovina", alpha_2: "BA", alpha_3: "BIH" },
    { country: "Botswana", alpha_2: "BW", alpha_3: "BWA" },
    { country: "Bouvet Island", alpha_2: "BV", alpha_3: "BVT" },
    { country: "Brazil", alpha_2: "BR", alpha_3: "BRA" },
    { country: "British Virgin Islands", alpha_2: "VG", alpha_3: "VGB" },
    {
      country: "British Indian Ocean Territory",
      alpha_2: "IO",
      alpha_3: "IOT",
    },
    { country: "Brunei Darussalam", alpha_2: "BN", alpha_3: "BRN" },
    { country: "Bulgaria", alpha_2: "BG", alpha_3: "BGR" },
    { country: "Burkina Faso", alpha_2: "BF", alpha_3: "BFA" },
    { country: "Burundi", alpha_2: "BI", alpha_3: "BDI" },
    { country: "Cambodia", alpha_2: "KH", alpha_3: "KHM" },
    { country: "Cameroon", alpha_2: "CM", alpha_3: "CMR" },
    { country: "Canada", alpha_2: "CA", alpha_3: "CAN" },
    { country: "Cape Verde", alpha_2: "CV", alpha_3: "CPV" },
    { country: "Cayman Islands ", alpha_2: "KY", alpha_3: "CYM" },
    { country: "Central African Republic", alpha_2: "CF", alpha_3: "CAF" },
    { country: "Chad", alpha_2: "TD", alpha_3: "TCD" },
    { country: "Chile", alpha_2: "CL", alpha_3: "CHL" },
    { country: "China", alpha_2: "CN", alpha_3: "CHN" },
    { country: "Hong Kong, SAR China", alpha_2: "HK", alpha_3: "HKG" },
    { country: "Macao, SAR China", alpha_2: "MO", alpha_3: "MAC" },
    { country: "Christmas Island", alpha_2: "CX", alpha_3: "CXR" },
    { country: "Cocos (Keeling) Islands", alpha_2: "CC", alpha_3: "CCK" },
    { country: "Colombia", alpha_2: "CO", alpha_3: "COL" },
    { country: "Comoros", alpha_2: "KM", alpha_3: "COM" },
    { country: "Congo (Brazzaville) ", alpha_2: "CG", alpha_3: "COG" },
    { country: "Congo, (Kinshasa)", alpha_2: "CD", alpha_3: "COD" },
    { country: "Cook Islands", alpha_2: "CK", alpha_3: "COK" },
    { country: "Costa Rica", alpha_2: "CR", alpha_3: "CRI" },
    { country: "Côte d'Ivoire", alpha_2: "CI", alpha_3: "CIV" },
    { country: "Croatia", alpha_2: "HR", alpha_3: "HRV" },
    { country: "Cuba", alpha_2: "CU", alpha_3: "CUB" },
    { country: "Cyprus", alpha_2: "CY", alpha_3: "CYP" },
    { country: "Czech Republic", alpha_2: "CZ", alpha_3: "CZE" },
    { country: "Denmark", alpha_2: "DK", alpha_3: "DNK" },
    { country: "Djibouti", alpha_2: "DJ", alpha_3: "DJI" },
    { country: "Dominica", alpha_2: "DM", alpha_3: "DMA" },
    { country: "Dominican Republic", alpha_2: "DO", alpha_3: "DOM" },
    { country: "Ecuador", alpha_2: "EC", alpha_3: "ECU" },
    { country: "England", alpha_2: "GB", alpha_3: "GBR" },
    { country: "Egypt", alpha_2: "EG", alpha_3: "EGY" },
    { country: "El Salvador", alpha_2: "SV", alpha_3: "SLV" },
    { country: "Equatorial Guinea", alpha_2: "GQ", alpha_3: "GNQ" },
    { country: "Eritrea", alpha_2: "ER", alpha_3: "ERI" },
    { country: "Estonia", alpha_2: "EE", alpha_3: "EST" },
    { country: "Ethiopia", alpha_2: "ET", alpha_3: "ETH" },
    { country: "Falkland Islands (Malvinas) ", alpha_2: "FK", alpha_3: "FLK" },
    { country: "Faroe Islands", alpha_2: "FO", alpha_3: "↵FRO" },
    { country: "Fiji", alpha_2: "FJ", alpha_3: "FJI" },
    { country: "Finland", alpha_2: "FI", alpha_3: "FIN" },
    { country: "France", alpha_2: "FR", alpha_3: "FRA" },
    { country: "French Guiana", alpha_2: "GF", alpha_3: "GUF" },
    { country: "French Polynesia", alpha_2: "PF", alpha_3: "PYF" },
    { country: "French Southern Territories ", alpha_2: "TF", alpha_3: "ATF" },
    { country: "Gabon", alpha_2: "GA", alpha_3: "GAB" },
    { country: "Gambia", alpha_2: "GM", alpha_3: "GMB" },
    { country: "Georgia", alpha_2: "GE", alpha_3: "GEO" },
    { country: "Germany", alpha_2: "DE", alpha_3: "DEU" },
    { country: "Ghana", alpha_2: "GH", alpha_3: "GHA" },
    { country: "Gibraltar", alpha_2: "GI", alpha_3: "GIB" },
    { country: "Greece", alpha_2: "GR", alpha_3: "GRC" },
    { country: "Greenland", alpha_2: "GL", alpha_3: "GRL" },
    { country: "Grenada", alpha_2: "GD", alpha_3: "GRD" },
    { country: "Guadeloupe", alpha_2: "GP", alpha_3: "GLP" },
    { country: "Guam", alpha_2: "GU", alpha_3: "GUM" },
    { country: "Guatemala", alpha_2: "GT", alpha_3: "GTM" },
    { country: "Guernsey", alpha_2: "GG", alpha_3: "GGY" },
    { country: "Guinea", alpha_2: "GN", alpha_3: "GIN" },
    { country: "Guinea-Bissau", alpha_2: "GW", alpha_3: "GNB" },
    { country: "Guyana", alpha_2: "GY", alpha_3: "GUY" },
    { country: "Haiti", alpha_2: "HT", alpha_3: "HTI" },
    { country: "Heard and Mcdonald Islands", alpha_2: "HM", alpha_3: "HMD" },
    { country: "Holy See (Vatican City State)", alpha_2: "VA", alpha_3: "VAT" },
    { country: "Honduras", alpha_2: "HN", alpha_3: "HND" },
    { country: "Hungary", alpha_2: "HU", alpha_3: "HUN" },
    { country: "Iceland", alpha_2: "IS", alpha_3: "ISL" },
    { country: "India", alpha_2: "IN", alpha_3: "IND" },
    { country: "Indonesia", alpha_2: "ID", alpha_3: "IDN" },
    { country: "Iran, Islamic Republic of", alpha_2: "IR", alpha_3: "IRN" },
    { country: "Iraq", alpha_2: "IQ", alpha_3: "IRQ" },
    { country: "Ireland", alpha_2: "IE", alpha_3: "IRL" },
    { country: "Isle of Man", alpha_2: "IM", alpha_3: "IMN" },
    { country: "Israel", alpha_2: "IL", alpha_3: "ISR" },
    { country: "Italy", alpha_2: "IT", alpha_3: "ITA" },
    { country: "Jamaica", alpha_2: "JM", alpha_3: "JAM" },
    { country: "Japan", alpha_2: "JP", alpha_3: "JPN" },
    { country: "Jersey", alpha_2: "JE", alpha_3: "JEY" },
    { country: "Jordan", alpha_2: "JO", alpha_3: "JOR" },
    { country: "Kazakhstan", alpha_2: "KZ", alpha_3: "KAZ" },
    { country: "Kenya", alpha_2: "KE", alpha_3: "KEN" },
    { country: "Kiribati", alpha_2: "KI", alpha_3: "KIR" },
    { country: "Korea (North)", alpha_2: "KP", alpha_3: "PRK" },
    { country: "Korea (South)", alpha_2: "KR", alpha_3: "KOR" },
    { country: "Kuwait", alpha_2: "KW", alpha_3: "KWT" },
    { country: "Kyrgyzstan", alpha_2: "KG", alpha_3: "KGZ" },
    { country: "Lao PDR", alpha_2: "LA", alpha_3: "LAO" },
    { country: "Latvia", alpha_2: "LV", alpha_3: "LVA" },
    { country: "Lebanon", alpha_2: "LB", alpha_3: "LBN" },
    { country: "Lesotho", alpha_2: "LS", alpha_3: "LSO" },
    { country: "Liberia", alpha_2: "LR", alpha_3: "LBR" },
    { country: "Libya", alpha_2: "LY", alpha_3: "LBY" },
    { country: "Liechtenstein", alpha_2: "LI", alpha_3: "LIE" },
    { country: "Lithuania", alpha_2: "LT", alpha_3: "LTU" },
    { country: "Luxembourg", alpha_2: "LU", alpha_3: "LUX" },
    { country: "Macedonia, Republic of", alpha_2: "MK", alpha_3: "MKD" },
    { country: "Madagascar", alpha_2: "MG", alpha_3: "MDG" },
    { country: "Malawi", alpha_2: "MW", alpha_3: "MWI" },
    { country: "Malaysia", alpha_2: "MY", alpha_3: "MYS" },
    { country: "Maldives", alpha_2: "MV", alpha_3: "MDV" },
    { country: "Mali", alpha_2: "ML", alpha_3: "MLI" },
    { country: "Malta", alpha_2: "MT", alpha_3: "MLT" },
    { country: "Marshall Islands", alpha_2: "MH", alpha_3: "MHL" },
    { country: "Martinique", alpha_2: "MQ", alpha_3: "MTQ" },
    { country: "Mauritania", alpha_2: "MR", alpha_3: "MRT" },
    { country: "Mauritius", alpha_2: "MU", alpha_3: "MUS" },
    { country: "Mayotte", alpha_2: "YT", alpha_3: "MYT" },
    { country: "Mexico", alpha_2: "MX", alpha_3: "MEX" },
    {
      country: "Micronesia, Federated States of",
      alpha_2: "FM",
      alpha_3: "FSM",
    },
    { country: "Moldova", alpha_2: "MD", alpha_3: "MDA" },
    { country: "Monaco", alpha_2: "MC", alpha_3: "MCO" },
    { country: "Mongolia", alpha_2: "MN", alpha_3: "MNG" },
    { country: "Montenegro", alpha_2: "ME", alpha_3: "MNE" },
    { country: "Montserrat", alpha_2: "MS", alpha_3: "MSR" },
    { country: "Morocco", alpha_2: "MA", alpha_3: "MAR" },
    { country: "Mozambique", alpha_2: "MZ", alpha_3: "MOZ" },
    { country: "Myanmar", alpha_2: "MM", alpha_3: "MMR" },
    { country: "Namibia", alpha_2: "NA", alpha_3: "NAM" },
    { country: "Nauru", alpha_2: "NR", alpha_3: "NRU" },
    { country: "Nepal", alpha_2: "NP", alpha_3: "NPL" },
    { country: "Netherlands", alpha_2: "NL", alpha_3: "NLD" },
    { country: "Netherlands Antilles", alpha_2: "AN", alpha_3: "ANT" },
    { country: "New Caledonia", alpha_2: "NC", alpha_3: "NCL" },
    { country: "New Zealand", alpha_2: "NZ", alpha_3: "NZL" },
    { country: "Nicaragua", alpha_2: "NI", alpha_3: "NIC" },
    { country: "Niger", alpha_2: "NE", alpha_3: "NER" },
    { country: "Nigeria", alpha_2: "NG", alpha_3: "NGA" },
    { country: "Niue", alpha_2: "NU", alpha_3: "NIU" },
    { country: "Norfolk Island", alpha_2: "NF", alpha_3: "NFK" },
    { country: "Northern Mariana Islands", alpha_2: "MP", alpha_3: "MNP" },
    { country: "Norway", alpha_2: "NO", alpha_3: "NOR" },
    { country: "Oman", alpha_2: "OM", alpha_3: "OMN" },
    { country: "Pakistan", alpha_2: "PK", alpha_3: "PAK" },
    { country: "Palau", alpha_2: "PW", alpha_3: "PLW" },
    { country: "Palestinian Territory", alpha_2: "PS", alpha_3: "↵PSE" },
    { country: "Panama", alpha_2: "PA", alpha_3: "PAN" },
    { country: "Papua New Guinea", alpha_2: "PG", alpha_3: "PNG" },
    { country: "Paraguay", alpha_2: "PY", alpha_3: "PRY" },
    { country: "Peru", alpha_2: "PE", alpha_3: "PER" },
    { country: "Philippines", alpha_2: "PH", alpha_3: "PHL" },
    { country: "Pitcairn", alpha_2: "PN", alpha_3: "PCN" },
    { country: "Poland", alpha_2: "PL", alpha_3: "POL" },
    { country: "Portugal", alpha_2: "PT", alpha_3: "PRT" },
    { country: "Puerto Rico", alpha_2: "PR", alpha_3: "PRI" },
    { country: "Qatar", alpha_2: "QA", alpha_3: "QAT" },
    { country: "Réunion", alpha_2: "RE", alpha_3: "REU" },
    { country: "Romania", alpha_2: "RO", alpha_3: "ROU" },
    { country: "Russian Federation ", alpha_2: "RU", alpha_3: "RUS" },
    { country: "Rwanda", alpha_2: "RW", alpha_3: "RWA" },
    { country: "Saint-Barthélemy", alpha_2: "BL", alpha_3: "BLM" },
    { country: "Saint Helena", alpha_2: "SH", alpha_3: "SHN" },
    { country: "Saint Kitts and Nevis", alpha_2: "KN", alpha_3: "KNA" },
    { country: "Saint Lucia", alpha_2: "LC", alpha_3: "LCA" },
    { country: "Saint-Martin (French part)", alpha_2: "MF", alpha_3: "MAF" },
    { country: "Saint Pierre and Miquelon ", alpha_2: "PM", alpha_3: "SPM" },
    { country: "Saint Vincent and Grenadines", alpha_2: "VC", alpha_3: "VCT" },
    { country: "Samoa", alpha_2: "WS", alpha_3: "WSM" },
    { country: "San Marino", alpha_2: "SM", alpha_3: "SMR" },
    { country: "Sao Tome and Principe", alpha_2: "ST", alpha_3: "STP" },
    { country: "Saudi Arabia", alpha_2: "SA", alpha_3: "SAU" },
    { country: "Senegal", alpha_2: "SN", alpha_3: "SEN" },
    { country: "Serbia", alpha_2: "RS", alpha_3: "SRB" },
    { country: "Seychelles", alpha_2: "SC", alpha_3: "SYC" },
    { country: "Sierra Leone", alpha_2: "SL", alpha_3: "SLE" },
    { country: "Singapore", alpha_2: "SG", alpha_3: "SGP" },
    { country: "Slovakia", alpha_2: "SK", alpha_3: "SVK" },
    { country: "Slovenia", alpha_2: "SI", alpha_3: "SVN" },
    { country: "Solomon Islands", alpha_2: "SB", alpha_3: "SLB" },
    { country: "Somalia", alpha_2: "SO", alpha_3: "SOM" },
    { country: "South Africa", alpha_2: "ZA", alpha_3: "ZAF" },
    {
      country: "South Georgia and the South Sandwich Islands",
      alpha_2: "GS",
      alpha_3: "SGS",
    },
    { country: "South Sudan", alpha_2: "SS", alpha_3: "SSD" },
    { country: "Spain", alpha_2: "ES", alpha_3: "ESP" },
    { country: "Sri Lanka", alpha_2: "LK", alpha_3: "LKA" },
    { country: "Sudan", alpha_2: "SD", alpha_3: "SDN" },
    { country: "Suriname", alpha_2: "SR", alpha_3: "SUR" },
    {
      country: "Svalbard and Jan Mayen Islands ",
      alpha_2: "SJ",
      alpha_3: "SJM",
    },
    { country: "Swaziland", alpha_2: "SZ", alpha_3: "SWZ" },
    { country: "Sweden", alpha_2: "SE", alpha_3: "SWE" },
    { country: "Switzerland", alpha_2: "CH", alpha_3: "CHE" },
    { country: "Syrian Arab Republic (Syria)", alpha_2: "SY", alpha_3: "SYR" },
    { country: "Taiwan, Republic of China ", alpha_2: "TW", alpha_3: "TWN" },
    { country: "Tajikistan", alpha_2: "TJ", alpha_3: "TJK" },
    { country: "Tanzania, United Republic of ", alpha_2: "TZ", alpha_3: "TZA" },
    { country: "Thailand", alpha_2: "TH", alpha_3: "THA" },
    { country: "Timor-Leste", alpha_2: "TL", alpha_3: "TLS" },
    { country: "Togo", alpha_2: "TG", alpha_3: "TGO" },
    { country: "Tokelau ", alpha_2: "TK", alpha_3: "TKL" },
    { country: "Tonga", alpha_2: "TO", alpha_3: "TON" },
    { country: "Trinidad and Tobago", alpha_2: "TT", alpha_3: "TTO" },
    { country: "Tunisia", alpha_2: "TN", alpha_3: "TUN" },
    { country: "Turkey", alpha_2: "TR", alpha_3: "TUR" },
    { country: "Turkmenistan", alpha_2: "TM", alpha_3: "TKM" },
    { country: "Turks and Caicos Islands ", alpha_2: "TC", alpha_3: "TCA" },
    { country: "Tuvalu", alpha_2: "TV", alpha_3: "TUV" },
    { country: "Uganda", alpha_2: "UG", alpha_3: "UGA" },
    { country: "Ukraine", alpha_2: "UA", alpha_3: "UKR" },
    { country: "United Arab Emirates", alpha_2: "AE", alpha_3: "ARE" },
    { country: "United Kingdom", alpha_2: "GB", alpha_3: "GBR" },
    { country: "United States", alpha_2: "US", alpha_3: "USA" },
    { country: "US Minor Outlying Islands ", alpha_2: "UM", alpha_3: "UMI" },
    { country: "Uruguay", alpha_2: "UY", alpha_3: "URY" },
    { country: "Uzbekistan", alpha_2: "UZ", alpha_3: "UZB" },
    { country: "Vanuatu", alpha_2: "VU", alpha_3: "VUT" },
    {
      country: "Venezuela (Bolivarian Republic)",
      alpha_2: "VE",
      alpha_3: "VEN",
    },
    { country: "Viet Nam", alpha_2: "VN", alpha_3: "VNM" },
    { country: "Virgin Islands, US", alpha_2: "VI", alpha_3: "VIR" },
    { country: "Wallis and Futuna Islands", alpha_2: "WF", alpha_3: "WLF" },
    { country: "Western Sahara ", alpha_2: "EH", alpha_3: "ESH" },
    { country: "Yemen", alpha_2: "YE", alpha_3: "YEM" },
    { country: "Zambia", alpha_2: "ZM", alpha_3: "ZMB" },
    { country: "Zimbabwe", alpha_2: "ZW", alpha_3: "ZWE" },
  ];

  if (code) {
    if (code === "all") {
      return arr;
    }

    if (code.length > 3) {
      for (const c of arr) {
        if (c.country === code) {
          return c[wantedKey];
        }
      }
    } else if (code.length === 3) {
      for (const c of arr) {
        if (c.alpha_3 === code) {
          return c[wantedKey];
        }
      }
    } else {
      for (const c of arr) {
        if (c.alpha_2 === code) {
          return c[wantedKey];
        }
      }
    }

    // Fallback
    return code;
  }

  return null;
}
