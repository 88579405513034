import axios from "axios";

export default async function fetchLanguageList() {
  const HOST = process.env.VUE_APP_API_URL;
  const URL = `${HOST}/get-languages`;

  return await axios
    .get(URL)
    .then((res) => res?.data?.languages?.map((l) => l?.toLowerCase()))
    .catch((err) => {
      console.error("error getting languages", err);
      return [];
    });
}
