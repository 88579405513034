<template>
  <div class="flex md:flex-col flex-wrap gap-4 w-full">
    <!-- Handle search -->
    <Search placeholder="Search by handle" :value="handleSearch" class="w-full" @onChange="setHandleSearch" />

    <!-- Followers -->
    <Tile class="p-4 mx-auto w-full">
      <div class="flex" :class="showFollowers ? 'mb-4' : ''">
        <Title title="Followers" />

        <Button
          class="ml-auto pointer"
          kind="link"
          :class="showFollowers ? 'transform rotate-180' : ''"
          @clicked="showFollowers = !showFollowers"
        >
          <Icon icon="arrow" width="24" height="24" />
        </Button>
      </div>

      <div v-if="showFollowers">
        <InputRange :min="0" :max="9999999" @sliderChange="setMinMaxFollowers" />
      </div>
    </Tile>

    <!-- Language -->
    <Tile class="p-4 mx-auto w-full">
      <div class="flex" :class="showLanguage ? 'mb-4' : ''">
        <Title title="Language" />

        <Button
          class="ml-auto pointer"
          kind="link"
          :class="showLanguage ? 'transform rotate-180' : ''"
          @clicked="showLanguage = !showLanguage"
        >
          <Icon icon="arrow" width="24" height="24" />
        </Button>
      </div>

      <div v-if="showLanguage">
        <Search placeholder="Search by language" :value="languageSearch" class="pb-6" @onChange="setLanguageSearch" />

        <div class="overflow-y-scroll pr-2 max-h-40">
          <div v-for="lang of filteredLanguages" :key="lang.index" class="flex py-2 capitalize">
            <p
              class="font-medium"
              :class="lang.checked ? 'text-white-default' : 'text-blue-lighter'"
              @click="lang.checked = !lang.checked"
            >
              {{ lang.name }}
            </p>

            <input v-model="lang.checked" type="checkbox" class="ml-auto" />
          </div>
        </div>
      </div>
    </Tile>

    <!-- Country -->
    <Tile class="p-4 mx-auto w-full">
      <div class="flex" :class="showCountry ? 'mb-4' : ''">
        <Title title="Country" />

        <Button
          class="ml-auto pointer"
          kind="link"
          :class="showCountry ? 'transform rotate-180' : ''"
          @clicked="showCountry = !showCountry"
        >
          <Icon icon="arrow" width="24" height="24" />
        </Button>
      </div>

      <div v-if="showCountry">
        <Search placeholder="Search by country" :value="countrySearch" class="pb-6" @onChange="setCountrySearch" />

        <div class="overflow-y-scroll pr-2 max-h-40">
          <div v-for="country of filteredCountries" :key="country.index" class="flex py-2 capitalize">
            <p
              class="font-medium"
              :class="country.checked ? 'text-white-default' : 'text-blue-lighter'"
              @click="country.checked = !country.checked"
            >
              {{ country.name }}
            </p>

            <input v-model="country.checked" type="checkbox" class="ml-auto" />
          </div>
        </div>
      </div>
    </Tile>

    <slot />
  </div>
</template>

<script>
import { Tile, Title, Search, Icon, Button } from "cavea-design-system";
import fetchLanguageList from "@/lib/fetchLanguageList";
import countryCode from "@/lib/helpers/countryCodes";

export default {
  name: "DiscoverFilter",

  components: {
    Tile,
    Icon,
    Title,
    Search,
    Button,
    InputRange: () => import("@/components/inputs/InputRange"),
  },

  data() {
    return {
      handleSearch: "",

      showFollowers: false,
      followerCount: {
        min: 0,
        max: 9999999,
      },

      showLanguage: false,
      languageList: null,
      languageSearch: "",

      showCountry: false,
      countrySearch: "",
      countriesList: null,

      timeoutFilter: null,
    };
  },

  computed: {
    filteredLanguages() {
      return this.languageList?.filter((l) => l?.name?.indexOf(this.languageSearch) !== -1);
    },

    filteredCountries() {
      return this.countriesList.filter((c) => c?.name?.indexOf(this.countrySearch) !== -1);
    },

    query() {
      return {
        username: this.handleSearch?.toLowerCase(),
        minFollowerCount: this.followerCount?.min,
        maxFollowerCount: this.followerCount?.max,
        languages: this.languageList?.filter((l) => l?.checked === true)?.map((l) => l?.name),
        countries: this.countriesList?.filter((c) => c?.checked === true)?.map((c) => c?.name),
      };
    },
  },

  watch: {
    query: {
      deep: true,

      handler() {
        clearTimeout(this.timeoutFilter);

        this.timeoutFilter = setTimeout(() => {
          this.$emit("queryChange", this.query);
        }, 1000);
      },
    },
  },

  async created() {
    this.getLanguageList();
    this.getCountriesList();
  },

  methods: {
    setHandleSearch(searchInput) {
      this.handleSearch = searchInput;
    },

    setMinMaxFollowers(values) {
      if (values?.min !== null && values?.min !== undefined) {
        this.followerCount.min = parseInt(values.min, 10) || 0;
      }

      if (values?.max) {
        this.followerCount.max = parseInt(values.max, 10);
      }
    },

    setLanguageSearch(searchInput) {
      this.languageSearch = searchInput;
    },

    setCountrySearch(searchInput) {
      this.countrySearch = searchInput;
    },

    async getLanguageList() {
      this.languageList = await fetchLanguageList().then((result) =>
        result?.map((l, i) => ({
          index: i,
          name: l,
          checked: false,
        }))
      );
    },

    getCountriesList() {
      this.countriesList = countryCode("all").map((c, i) => ({
        index: i,
        name: c?.country?.toLowerCase(),
        checked: false,
      }));
    },
  },
};
</script>
